import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../styles/Log.css";

const Logs: React.FC = () => {
  const [version, setVersion] = useState<string[]>([]);
  const [game, setGame] = useState<string>("all");
  const [platform, setPlatform] = useState<string>("all");
  const [type, setType] = useState<string>("all");
  const [userId, setUserId] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const [showPopup, setShowPopup] = useState(false);
  const [runStatus, setRunStatus] = useState("");
  const [resultData, setResultData] = useState<string>("");

  const gameOptions = [
    { value: "all", label: "All" },
    { value: "find-differences", label: "Find Differences" },
    { value: "find-hidden", label: "Find Hidden Objects" },
    { value: "find-master", label: "Find Master" },
  ];

  const platformOptions = [
    { value: "all", label: "All" },
    { value: "and", label: "Android" },
    { value: "ios", label: "iOS" },
  ];

  const typeOptions = [
    { value: "all", label: "All" },
    { value: "autocatch", label: "Auto Catch" },
    { value: "usermail", label: "User Mail" },
  ];

  useEffect(() => {
    const today = new Date();
    const lastWeek = new Date();
    lastWeek.setDate(today.getDate() - 7);
    setDateRange([lastWeek, today]); // Varsayılan: son 1 hafta
  }, []);

  // Milisaniye olmadan tarih formatı: "YYYY-MM-DDTHH:mm:ssZ"
  const formatLocalDate = (date: Date) => {
    const pad = (n: number, digits = 2) => n.toString().padStart(digits, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}Z`;
  };

  const handleRun = async () => {
    // Request body’sini oluşturuyoruz.
    const filterObject: any = {};

    if (game !== "all") {
      filterObject.game = game;
    }
    if (dateRange[0]) {
      const start = new Date(dateRange[0]);
      start.setHours(0, 0, 0, 0);
      filterObject.startDate = formatLocalDate(start);
    }
    if (dateRange[1]) {
      const end = new Date(dateRange[1]);
      end.setHours(23, 59, 59, 0); // Milisaniye 0
      filterObject.endDate = formatLocalDate(end);
    }
    if (userId.length > 0) {
      filterObject.userId = userId.length === 1 ? userId[0] : userId;
    }
    if (platform !== "all") {
      filterObject.platform = platform;
    }
    if (type !== "all") {
      filterObject.type = type;
    }
    if (version.length > 0) {
      filterObject.version = version.length === 1 ? version[0] : version;
    }

    // Popup'ı açıp "Running" mesajı ve spinner animasyonu gösteriyoruz.
    setRunStatus("Running...");
    setResultData("");
    setShowPopup(true);

    try {
      // Cloud Function'a istek gönderiyoruz.
      const response = await fetch(
        "https://logs-462nclm3ma-uc.a.run.app/files",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(filterObject),
        }
      );

      if (!response.ok) {
        throw new Error("Cloud function error");
      }

      const data = await response.json();

      // Yeni response yapısı: data.jobs, her job için ayrı bilgiler içeriyor.
      if (data.jobs && Array.isArray(data.jobs)) {
        const resultStr = data.jobs
          .map((job: any, index: number) => {
            return `
              <div>
                <strong>Job ${index + 1}:</strong><br />
                <strong>Game:</strong> ${job.game} <br />
                <strong>Log Count:</strong> ${job.logCount} <br />
                <strong>Unique User Count:</strong> ${job.uniqueUserCount} <br />
                <strong>Job ID:</strong> ${job.jobId} <br />
                <strong>Status URL:</strong> 
                <a href="https://internal.yologamestudios.com/status/${job.jobId}" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  https://internal.yologamestudios.com/status/${job.jobId}
                </a>
              </div>
              <br />
            `;
          })
          .join("");

        // Her job için statusUrl’i yeni bir sekmede açıyoruz.
        data.jobs.forEach((job: any) => {
          if (job.statusUrl) {
            window.open(job.statusUrl, "_blank");
          }
        });

        setRunStatus("Run completed");
        setResultData(resultStr);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (err: any) {
      console.error(err);
      setRunStatus("Run failed");
      setResultData("Error: " + err.message);
    }
  };

  const handlePreset = (days: number) => {
    const today = new Date();
    const startDate = new Date();
    startDate.setDate(today.getDate() - days);
    setDateRange([startDate, today]);
  };

  const resetDateRange = () => setDateRange([null, null]);
  const setYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    setDateRange([yesterday, yesterday]);
  };

  const handleGameChange = (selected: any) => setGame(selected.value);
  const handlePlatformChange = (selected: any) => setPlatform(selected.value);
  const handleTypeChange = (selected: any) => setType(selected.value);

  const versionRegex = /^\d+\.\d+(\.\d+)*$/;

  const handleVersionKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.currentTarget.value.trim();
      if (!value) return;
      if (version.includes(value)) {
        alert("Bu versiyon zaten eklenmiş!");
        return;
      }
      if (!versionRegex.test(value)) {
        alert("Lütfen geçerli bir versiyon girin (örnek: 2.3 veya 2.4.5)");
        return;
      }
      setVersion((prev) => [...prev, value]);
      e.currentTarget.value = "";
    } else if (e.key === "Backspace" && e.currentTarget.value.trim() === "") {
      setVersion((prev) => prev.slice(0, -1));
    }
  };

  const handleUserIdKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.currentTarget.value.trim();
      if (!value) return;
      if (userId.includes(value)) {
        alert("Bu kullanıcı ID zaten eklenmiş!");
        return;
      }
      setUserId((prev) => [...prev, value]);
      e.currentTarget.value = "";
    } else if (e.key === "Backspace" && e.currentTarget.value.trim() === "") {
      setUserId((prev) => prev.slice(0, -1));
    }
  };

  const removeVersion = (index: number) =>
    setVersion((prev) => prev.filter((_, i) => i !== index));
  const removeUserId = (index: number) =>
    setUserId((prev) => prev.filter((_, i) => i !== index));
  const handleClosePopup = () => setShowPopup(false);

  return (
    <div className="log-container">
      <h1>Log Sayfası</h1>
      <form className="log-form">
        <div className="form-group">
          <label>Games:</label>
          <Select
            options={gameOptions}
            onChange={handleGameChange}
            value={gameOptions.find((option) => option.value === game)}
          />
        </div>
        <div className="form-group">
          <label>Platform:</label>
          <Select
            options={platformOptions}
            onChange={handlePlatformChange}
            value={platformOptions.find((option) => option.value === platform)}
          />
        </div>
        <div className="form-group">
          <label>Log Type:</label>
          <Select
            options={typeOptions}
            onChange={handleTypeChange}
            value={typeOptions.find((option) => option.value === type)}
          />
        </div>
        <div className="form-group">
          <label>Version:</label>
          <div className="input-with-tags">
            {version.map((v, index) => (
              <span key={index} className="tag">
                {v}
                <button type="button" onClick={() => removeVersion(index)}>
                  ×
                </button>
              </span>
            ))}
            <input
              type="text"
              onKeyDown={handleVersionKeyDown}
              placeholder="Versiyon girin ve Enter'a basın"
            />
          </div>
        </div>
        <div className="form-group">
          <label>User ID:</label>
          <input
            type="text"
            className="user-id-input"
            onKeyDown={handleUserIdKeyDown}
            placeholder="Kullanıcı ID girin ve Enter'a basın"
          />
          <div className="tags">
            {userId.map((id, index) => (
              <span key={index} className="tag">
                {id}
                <button type="button" onClick={() => removeUserId(index)}>
                  ×
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label>Date Range:</label>
          <div className="date-picker-container">
            <DatePicker
              selected={dateRange[0]}
              onChange={(update) => {
                if (update) {
                  const [start, end] = update;
                  setDateRange([start, end]);
                } else {
                  setDateRange([null, null]);
                }
              }}
              startDate={dateRange[0] || undefined}
              endDate={dateRange[1] || undefined}
              selectsRange
              inline
              maxDate={new Date()}
            />
            <div className="right-container">
              <div className="preset-buttons">
                <button type="button" onClick={setYesterday}>
                  Yesterday
                </button>
                <button type="button" onClick={() => handlePreset(7)}>
                  Last 7 Days
                </button>
                <button type="button" onClick={() => handlePreset(15)}>
                  Last 15 Days
                </button>
                <button type="button" onClick={() => handlePreset(30)}>
                  Last 1 Month
                </button>
                <button type="button" onClick={() => handlePreset(90)}>
                  Last 3 Months
                </button>
                <button
                  type="button"
                  className="reset-button"
                  onClick={resetDateRange}
                >
                  Reset
                </button>
              </div>
              <div className="date-range-display">
                {dateRange[0] && (
                  <div className="date-item">
                    Start Date: {dateRange[0].toLocaleDateString()}
                  </div>
                )}
                {dateRange[1] && (
                  <div className="date-item">
                    End Date: {dateRange[1].toLocaleDateString()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button type="button" onClick={handleRun} className="run-button">
          Run
        </button>
      </form>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>{runStatus}</h2>
            {runStatus === "Running..." && (
              <div
                className="spinner"
                style={{
                  border: "4px solid #f3f3f3",
                  borderTop: "4px solid #3498db",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  animation: "spin 1s linear infinite",
                  margin: "10px auto",
                }}
              />
            )}
            <div dangerouslySetInnerHTML={{ __html: resultData }} />
            <button onClick={handleClosePopup} style={{ marginTop: "10px" }}>
              Close
            </button>
          </div>
        </div>
      )}
      {/* Spinner animasyonu için keyframes */}
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Logs;
