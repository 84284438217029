import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Status: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      window.location.replace(`https://logs-462nclm3ma-uc.a.run.app/status/${id}`);
    }
  }, [id]);

  return <p>Redirecting...</p>;
};

export default Status;