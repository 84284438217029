import React, { useEffect, useState, useMemo } from 'react';
import { initializeFirebaseForGame } from '../services/firebase'; // Firebase başlatma fonksiyonu
import firebase from 'firebase/app';
import 'firebase/remote-config';
import AWS from 'aws-sdk';
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import { CSSProperties } from 'react';
import axios from 'axios';

// Oyunlara göre AWS ayarları
const gameConfigs = {
    "Find Differences": {
        identityPoolId: "eu-central-1:b822910c-23cf-40f1-bffd-49fab9f08a27",
        bucket: "yolo-differences",
        region: "eu-central-1",
        prefix: "FTD-3_0/LevelLists/",
        bundleAndroidPrefix: "FTD-3_0/Levels/Bundles/ANDROID/",
        bundleIOSPrefix: "FTD-3_0/Levels/Bundles/IOS/",
        folderIDs: {
            filesFolder: '1nAT_H09QPykfy5uwIbzUQAoQ76raVOIr',
            foldersFolder: '19VCGA1Wv7DtBj0dxy79EUQvgb-DcGqa_',
            prefabFolder: '1dg5f_MLTLWEN3zPoNsiRg5FVlCluGJnT',
            androidBundleFolder: '1JfXJF2ervCdxTuXKuNNUM1eQ8vRYZurF',
            iosBundleFolder: '1AxE7Y6z0BAemQBv0cSjWvhepNwOYlwGj',
            thumbnailFolder: '1ogIqy3S2JAxNl9Ob6gfZSjdd-XApJfb-'
        }
    },
    "Find Hidden Objects": {
        identityPoolId: "eu-central-1:d751f06d-fac3-4980-9704-d76ef2354b0d",
        bucket: "yolo-hiddenobjects",
        region: "eu-central-1",
        prefix: "production/levellists/",
        bundleAndroidPrefix: "production/levels/bundles/android/",
        bundleIOSPrefix: "production/levels/bundles/ios/",
        folderIDs: {
            filesFolder: '1PKYi_mxZvZaEVPAUTfg6A2UPXXKigVLv',
            foldersFolder: '1j-rrQ52RBDqHSfPQt7VJhQFOmfTYe5oP',
            prefabFolder: '13nycNuFqNo8nvTXogzoNA1olg1aZKJ8q',
            androidBundleFolder: '1S98RxGX701mP77M9NUrXnucwF7jCyXn4',
            iosBundleFolder: '1XdunHfL4bjJ54b2g2xs8R9IXIxtw1TqG',
            thumbnailFolder: '1DkIpnqrzBmLsOKq6IuG-qBmksvOpHvKy'
        }
    },
    "Find Master": {
        identityPoolId: "eu-central-1:9c165972-2140-4518-9715-a94bd9575fd1",
        bucket: "yolo-findmaster",
        region: "eu-central-1",
        prefix: "production/levellists/",
        bundleAndroidPrefix: "production/levels/bundles/android/",
        bundleIOSPrefix: "production/levels/bundles/ios/",
        folderIDs: {
            filesFolder: '1NmTmAl7Ij4bg7A3g4E4axmdNcImXIxAt',
            foldersFolder: '1fdVBqnlc5Aw6olyTX9sDcu1lbJAMsweO',
            prefabFolder: '1D057LpaCgG9rqeHFtIoet2t8W3E0Beab',
            androidBundleFolder: '14rBKYwOqb0bPdzqq6pQ2j1LHkhIjvOZ8',
            iosBundleFolder: '1eSpvRoTbHYrkJCEHwXpHhXN_zGLDbBqr',
            thumbnailFolder: '1g1EcS9W8ksbAM5gPxXMtvM8IxpRbJKMd'
        }
    }
};
const sourceFileFolderConfigs = {
    "Find Differences": {
        sourceFolderId: null, // SourceFile burada gözükmeyecek
    },
    "Find Hidden Objects": {
        sourceFolderId: "HIDDEN_OBJECTS_SOURCE_FOLDER_ID",
    },
    "Find Master": {
        sourceFolderId: "FIND_MASTER_SOURCE_FOLDER_ID",
    }
};

// Stil nesnelerini doğru tip ile tanımla
const tableHeaderStyle: CSSProperties = {
    border: '1px solid black',
    padding: '8px',
    backgroundColor: '#f2f2f2',
    textAlign: 'center', // TypeScript bunu doğru tanımlar.
};

const tableCellStyle: CSSProperties = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center',
};
const previewStyle: React.CSSProperties = {
    position: 'fixed',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#fff',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    zIndex: 1000,
};


const compareDateWithNow = (dateObj: any) => {
    const now = new Date();
    const versionDate = new Date(dateObj.Year, dateObj.Month - 1, dateObj.Day, dateObj.hour, dateObj.minute, dateObj.second);
    return versionDate < now;
};

const getListVersionFromJson = (jsonData: any, key: string) => {
    const list = jsonData[key] || jsonData.regularLevelTimeline;

    if (!list) {
        console.error(`getListVersionFromJson için gelen JSON boş veya undefined: key = ${key}`);
    } else {
        console.log("getListVersionFromJson için gelen JSON:", list);
    }

    if (!list || list.length === 0) return null;

    const sortedList = list.sort((a: any, b: any) => {
        const dateA = new Date(a.activationDate).getTime();
        const dateB = new Date(b.activationDate).getTime();
        return dateA - dateB;
    });

    let previousVersion = null;
    let validDate = null;

    for (let i = 0; i < sortedList.length; i++) {
        if (new Date(sortedList[i].activationDate) > new Date()) {
            validDate = sortedList[i];
            break;
        }
        previousVersion = sortedList[i].levelCount;
    }

    return {
        version: previousVersion,
        dateTime: validDate ? new Date(validDate.activationDate) : null
    };
};






const Levels: React.FC = () => {
    const [game, setGame] = useState<"Find Differences" | "Find Hidden Objects" | "Find Master">("Find Differences");
    const [mode, setMode] = useState<string>("regular");
    const [levellistversion, setLevellistversion] = useState<string>("");
    const [filteredNumbers, setFilteredNumbers] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [listCount, setListCount] = useState<number | null>(null);
    const [levelsData, setLevelsData] = useState<Level2[]>([]); // Başlangıç değeri boş dizi olarak ayarlandı
    const [creationDate, setCreationDate] = useState<string | null>(null);
    const [remoteConfigValue, setRemoteConfigValue] = useState<string | null>(null);
    const [activationDate, setActivationDate] = useState<string | null>(null);
    const [currentListVersion, setCurrentListVersion] = useState<string | null>(null);
    const [showCard, setShowCard] = useState(false);
    const [readyVersions, setReadyVersions] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [processingLevels, setProcessingLevels] = useState<string[]>([]);
    const [allSourceFiles, setallSourceFiles] = useState<FileInfo[]>([]);
    const [readAWS, setReadAws] = useState(false);
    const [filledAllSorceFiles, setfilledAllSorceFiles] = useState(false);
    const [allExportFolders, setallExportFolders] = useState<FolderInfo[]>([]);
    const [filledAllExportFolders, setfilledAllExportFolders] = useState(false);
    const [allPrefabFolders, setallPrefabFolders] = useState<FolderInfo[]>([]);
    const [filledAllPrefabFolders, setfilledAllPrefabFolders] = useState(false);
    const [allAndroidBundleFiles, setallAndroidBundleFiles] = useState<FileInfo[]>([]);
    const [filledAllAndroidBundleFiles, setfilledAllAndroidBundleFiles] = useState(false);
    const [allIOSBundleFiles, setallIOSBundleFiles] = useState<FileInfo[]>([]);
    const [filledAllIOSBundleFiles, setfilledAllIOSBundleFiles] = useState(false);
    const [allAwsAndroidFiles, setallAwsAndroidFiles] = useState<AwsInfo[]>([]);
    const [filledAllAndroidAwsFiles, setfilledAllAndroidAwsFiles] = useState(false);
    const [allAwsIOSFiles, setallAwsIOSFiles] = useState<AwsInfo[]>([]);
    const [filledAllIOSAwsFiles, setfilledAllIOSAwsFiles] = useState(false);
    const [allThumbnailFiles, setallThumbnailFiles] = useState<FileInfo[]>([]);
    const [filledAllThumbnailFiles, setfilledThumbnailFiles] = useState(false);
    const [sortOrder, setSortOrder] = useState<'ascending' | 'descending'>('descending');
    const sortedLevelsData = useMemo(() => {return sortOrder === 'ascending' ? levelsData : [...levelsData].reverse();}, [levelsData, sortOrder]);
    const [filteredFileNames, setFilteredFileNames] = useState<string[]>([]); // Diğer iki oyun için
    const [currentListName, setCurrentListName] = useState<string | null>(null);
    const [activationLevelTimeline, setActivationLevelTimeline] = useState<{ activationDate: string; levelCount: number }[]>([]);
    const [currentActivationDate, setCurrentActivationDate] = useState<string | null>(null);
    const [currentActivationLevelCount, setCurrentActivationLevelCount] = useState<number | null>(null);

    const sortLevellistVersions = (versions: string[]): string[] => {
        const defaultVersion = versions.find(v => v === 'regularLevelList.json') || '';
        const numericVersions = versions.filter(v => v.includes('_v'));
        const testVersions = versions.filter(v => v.includes('test'));
        const devVersions = versions.filter(v => v.includes('dev'));
        const otherVersions = versions.filter(v => 
            v !== 'regularLevelList.json' && 
            !v.includes('_v') && 
            !v.includes('test') && 
            !v.includes('dev')
        );
    
        return [
            ...(defaultVersion ? [defaultVersion] : []), 
            ...numericVersions.sort(), 
            ...testVersions.sort(),
            ...devVersions.sort(),
            ...otherVersions.sort()
        ];
    };
    
    useEffect(() => {
        setMode("regular");
    }, [game]);
    useEffect(() => {
        setShowCard(false);
    }, [game, mode, levellistversion]);

    useEffect(() => {
        const setupFirebase = async () => {
            try {
                console.log("Firebase başlatılıyor...");
                const firebaseApp = await initializeFirebaseForGame(game);
                const remoteConfig = getRemoteConfig(firebaseApp);

                remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // 1 saatlik fetch interval
                console.log("Remote Config fetch ediliyor...");

                await fetchAndActivate(remoteConfig);
                console.log("Remote Config aktif edildi.");

                // Oyun ve mod değiştiğinde doğru key'i belirleyelim
                var configKey = '';
                if (game === 'Find Differences') {
                    configKey = mode === 'regular' ? 'YOLO_regular_level_list_version' : 'YOLO_daily_level_list_version';
                } else if (game === 'Find Hidden Objects') {
                    configKey = 'YOLO_regular_level_timeline';
                } else if (game === 'Find Master') {
                    configKey = 'YOLO_regular_level_timeline';
                }

                // Remote config key'ini al ve değeri set et
                const value = getValue(remoteConfig, configKey).asString();
                console.log(`Game (${game}) ve mode (${mode}) için Remote Config'ten gelen versiyon:`, value);
                setRemoteConfigValue(value);  // Remote Config'ten gelen değeri sakla
                if (game === 'Find Hidden Objects' || game === 'Find Master') {
                    // AWS S3 üzerinden liste çekiliyor
                    await fetchRegularLevelListsFromAWS();
                }

            } catch (err) {
                console.error("Firebase ayarları yüklenirken bir hata oluştu:", err);
            }
        };
        setupFirebase(); // Firebase'i başlat
    }, [game, mode]); // 'game' ve 'mode' değiştiğinde tetiklenecek
    useEffect(() => {
        if (remoteConfigValue) {
            try {
                // remoteConfigValue'yu JSON olarak parse ediyoruz
                const jsonData = JSON.parse(remoteConfigValue);

                var version = ''; // Bulduğumuz list version burada tutulacak

                // Oyunlara göre listVersion değerini buluyoruz
                if (game === 'Find Hidden Objects' && Array.isArray(jsonData.compulsoryListUpdatesList)) {
                    // Find Hidden Objects için compulsoryListUpdatesList'teki son versiyonu al
                    version = jsonData.compulsoryListUpdatesList[jsonData.compulsoryListUpdatesList.length - 1].listVersion;
                } else if (game === 'Find Master' && Array.isArray(jsonData.list)) {
                    // Find Master için list'teki son versiyonu al
                    version = jsonData.list[jsonData.list.length - 1].listVersion;
                } else if (game === 'Find Differences') {
                    // Find Differences için value string olarak atanmış olabilir, direkt kullan
                    version = remoteConfigValue; // Zaten string ise
                }

                if (version) {
                    // listVersion'ı levellistversion olarak set ediyoruz
                    setLevellistversion(version.toString());
                    console.log(`Set edilen list version: ${version}`);
                } else {
                    console.error('Version bulunamadı.');
                }
            } catch (error) {
                console.error('remoteConfigValue doğru formatta değil:', error);
            }
        }
    }, [remoteConfigValue]);

    useEffect(() => {
        if (remoteConfigValue) {
            try {
                const jsonData = JSON.parse(remoteConfigValue);
                console.log("JSON Parse Sonucu:", jsonData);

                // Oyun adını kontrol ederek ilgili listeyi alıyoruz
                var versionList = [];
                if (game === 'Find Hidden Objects' && Array.isArray(jsonData.compulsoryListUpdatesList)) {
                    // Find Hidden Objects için compulsoryListUpdatesList'i kullan
                    versionList = jsonData.compulsoryListUpdatesList.map((item: any) => item.listVersion);
                    console.log("Find Hidden Objects Ready Versions:", versionList);
                } else if (game === 'Find Master' && Array.isArray(jsonData.list)) {
                    // Find Master için list'i kullan
                    versionList = jsonData.list.map((item: any) => item.listVersion);
                    console.log("Find Master Ready Versions:", versionList);
                } else {
                    console.error("JSON formatı hatalı veya list boş.");
                }

                // Hazır versiyonları state'e ekleyelim
                setReadyVersions(versionList);
                console.log("Ready List Versions Güncellendi:", versionList);

            } catch (error) {
                console.error("Remote Config'ten gelen değer doğru formatta değil:", error);
            }
        }
    }, [remoteConfigValue, game]); // game ve remoteConfigValue değiştiğinde tetiklenecek
    useEffect(() => {
        if (game === 'Find Hidden Objects' || game === 'Find Master') {
            try {
                const jsonData = JSON.parse(remoteConfigValue || '{}');
                if (jsonData.regularLevelTimeline && Array.isArray(jsonData.regularLevelTimeline)) {
                    const timeline = jsonData.regularLevelTimeline.sort(
                        (a: any, b: any) => new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime()
                    );
    
                    setActivationLevelTimeline(timeline);
    
                    const now = new Date();
                    const validEntry = timeline.find(
                        (entry: any) => new Date(entry.activationDate) < now
                    );
    
                    if (validEntry) {
                        setCurrentActivationDate(validEntry.activationDate);
                        setCurrentActivationLevelCount(validEntry.levelCount);
                    }
                }
            } catch (err) {
                console.error('Firebase timeline verisi hatalı:', err);
            }
        }
    }, [remoteConfigValue, game]);
    

    useEffect(() => {
        // Oyun değiştiğinde readyVersions'u sıfırlıyoruz
        setReadyVersions([]);
        console.log("Oyun değişti, readyVersions sıfırlandı.");
    }, [game]);

    useEffect(() => {
        const updateListVersion = () => {
            if (remoteConfigValue) {
                console.log("Game veya mode değiştiğinde Remote Config'ten gelen versiyon:", remoteConfigValue);
                setLevellistversion(remoteConfigValue);  // Remote Config'ten gelen versiyonu levellistversion olarak ayarla
            }
        };

        updateListVersion();  // Game veya mode değiştiğinde levellistversion güncelle
    }, [game, mode, remoteConfigValue]);  // Game, mode veya Remote Config değeri değiştiğinde tetiklenecek

    useEffect(() => {
        if (remoteConfigValue) {
            console.log("Remote Config'ten gelen levellistversion değeri: ", remoteConfigValue);
            setLevellistversion(remoteConfigValue);  // Remote config ile levellist version güncelle
        }
    }, [remoteConfigValue]);

    useEffect(() => {
        console.log("levellistversion state güncellendi: ", levellistversion);
    }, [levellistversion]);
    useEffect(() => {
        console.log("Dropdown'da gösterilen value (levellistversion): ", levellistversion);
    }, [levellistversion]);

    useEffect(() => {
        console.log("Filtrelenmiş numaralar: ", filteredNumbers);
    }, [filteredNumbers]);
    useEffect(() => {
        console.log("Game:", game);
        console.log("Mode:", mode);
        console.log("Remote Config Value:", remoteConfigValue);
        console.log("Seçili LevelList Version:", levellistversion);
    }, [game, mode, remoteConfigValue, levellistversion]);


    useEffect(() => {
        const updateActivationDateAndVersion = () => {
            if (game === 'Find Differences') {
                console.log("Find Differences için Remote Config değeri:", remoteConfigValue);

                if (remoteConfigValue === levellistversion) {
                    const lastFetchTime = new Date(Date.now()).toLocaleString();
                    console.log("RC'den gelen son güncelleme tarihi:", lastFetchTime);
                    setActivationDate(lastFetchTime);
                } else {
                    console.log("Seçili list version RC ile eşleşmiyor.");
                    setActivationDate(null);
                }

                setCurrentListVersion(remoteConfigValue);
            } else if (game === 'Find Hidden Objects' || game === 'Find Master') {
                if (remoteConfigValue) {
                    try {
                        const jsonData = JSON.parse(remoteConfigValue);
                        console.log("Remote Config JSON Parse Sonucu:", jsonData);
        
                        if (Array.isArray(jsonData.regularLevelTimeline)) {
                            // Şu anki tarihe en yakın aktivasyon tarihini bul
                            const now = new Date();
                            const validEntry = jsonData.regularLevelTimeline.find((entry: any) => {
                                return new Date(entry.activationDate) > now;
                            }) || jsonData.regularLevelTimeline[jsonData.regularLevelTimeline.length - 1];
        
                            console.log("Seçilen Aktivasyon Tarihi:", validEntry.activationDate);
                            console.log("Seçilen Level Count:", validEntry.levelCount);
        
                            setActivationDate(new Date(validEntry.activationDate).toLocaleString());
                            setListCount(validEntry.levelCount);
                        } else {
                            console.error("regularLevelTimeline dizisi mevcut değil.");
                        }
                    } catch (error) {
                        console.error("Remote Config verisi parse edilirken hata oluştu:", error);
                    }
                }
            }
        };

        updateActivationDateAndVersion();
    }, [game, mode, remoteConfigValue]);
    useEffect(() => {
        setError(null); // Hata mesajını sıfırlar
    }, [game, mode, levellistversion]);

    useEffect(() => {
        const updateActivationDateAndVersion = () => {
            if (game === 'Find Differences') {
                console.log("Find Differences için Remote Config değeri:", remoteConfigValue);

                if (remoteConfigValue === levellistversion) {
                    const lastFetchTime = new Date(Date.now()).toLocaleString();
                    console.log("RC'den gelen son güncelleme tarihi:", lastFetchTime);
                    setActivationDate(lastFetchTime);
                } else {
                    console.log("Seçili list version RC ile eşleşmiyor.");
                    setActivationDate(null);
                }

                setCurrentListVersion(remoteConfigValue);
            } else if (game === 'Find Hidden Objects' || game === 'Find Master') {
                if (remoteConfigValue) {
                    try {
                        const jsonData = JSON.parse(remoteConfigValue);
                        console.log("Remote Config JSON Parse Sonucu:", jsonData);
        
                        if (Array.isArray(jsonData.regularLevelTimeline)) {
                            // Şu anki tarihe en yakın aktivasyon tarihini bul
                            const now = new Date();
                            const validEntry = jsonData.regularLevelTimeline.find((entry: any) => {
                                return new Date(entry.activationDate) > now;
                            }) || jsonData.regularLevelTimeline[jsonData.regularLevelTimeline.length - 1];
        
                            console.log("Seçilen Aktivasyon Tarihi:", validEntry.activationDate);
                            console.log("Seçilen Level Count:", validEntry.levelCount);
        
                            setActivationDate(new Date(validEntry.activationDate).toLocaleString());
                            setListCount(validEntry.levelCount);
                        } else {
                            console.error("regularLevelTimeline dizisi mevcut değil.");
                        }
                    } catch (error) {
                        console.error("Remote Config verisi parse edilirken hata oluştu:", error);
                    }
                }
            }
        };

        updateActivationDateAndVersion();
    }, [levellistversion]);
    // S3 dosyalarını yükleme işlemi
    useEffect(() => {
        const loadS3FileNames = async () => {
            console.log("S3 dosya listesi yükleniyor...");
            const config = gameConfigs[game];
    
            AWS.config.region = config.region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: config.identityPoolId
            });
    
            try {
                const credentials = AWS.config.credentials;
                if (credentials instanceof AWS.Credentials) {
                    await new Promise((resolve, reject) => {
                        credentials.get((err?: AWS.AWSError) => {
                            if (err) reject(err);
                            else resolve(credentials);
                        });
                    });
                } else {
                    setError("AWS credentials yapılandırılmadı.");
                    return;
                }
    
                const s3 = new AWS.S3();
                const params = {
                    Bucket: config.bucket,
                    Prefix: config.prefix
                };
    
                const data = await s3.listObjectsV2(params).promise();
                const fileNames = data.Contents?.map(item => item.Key || '') || [];
    
                if (game === 'Find Differences') {
                    // Find Differences için filtreleme
                    const filtered = fileNames
                        .filter((fileName) => {
                            if (mode === 'regular') {
                                return fileName.includes('regularList');
                            } else if (mode === 'daily') {
                                return fileName.includes('dailyList');
                            }
                            return false;
                        })
                        .map((fileName) => {
                            const match = fileName.match(/(\d+)\.json$/);
                            return match ? parseInt(match[1], 10) : null;
                        })
                        .filter((num): num is number => num !== null)
                        .sort((a, b) => b - a);
    
                    console.log("Find Differences - Filtrelenmiş Dosyalar (Numbers):", filtered);
                    setFilteredNumbers(filtered);
                    setFilteredFileNames([]); // Diğer state'i sıfırla
                } else if (game === 'Find Hidden Objects' || game === 'Find Master') {
                    // Diğer iki oyun için filtreleme
                    const filtered = fileNames
                        .filter((fileName) => fileName.includes('regularLevelList'))
                        .map((fileName) => fileName.split('/').pop() || '')
                        .sort();
    
                    console.log("Find Hidden Objects/Master - Filtrelenmiş Dosyalar (Strings):", filtered);
                    setFilteredFileNames(filtered);
                    setFilteredNumbers([]); // Diğer state'i sıfırla
                }
    
            } catch (err) {
                setError("Dosyalar yüklenirken bir hata oluştu");
                console.error("S3 hata:", err);
            }
        };
    
        loadS3FileNames();
    }, [game, mode]);
    
    useEffect(() => {
        if (filledAllSorceFiles) {
          // Karşılaştırma ve güncelleme işlemi başlatılıyor
          console.log('filledAllSorceFiles true, karşılaştırma başlatılıyor...');
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const levelFileName = `${level.levelID}.psd`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allSourceFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= new DateInfo(matchingFile.creationDate);
              return {
                ...level,
                sourceFile: {
                  creationDate: matchingFile.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFile.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllSorceFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllSorceFiles, allSourceFiles, levelsData]);
      useEffect(() => {
        if (filledAllExportFolders) {
          const updatedLevelsData = levelsData.map((level) => {
            const levelFolderName = `${level.levelID}`;
            const matchingFolder = allExportFolders.find(folder => folder.foldername === levelFolderName);
      
            if (matchingFolder) {
              console.log(`Eşleşen klasör bulundu: ${matchingFolder.foldername}`);
              const dateInfo= new DateInfo(matchingFolder.creationDate);
              return {
                ...level,
                exportFile: {
                  creationDate: matchingFolder.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFolder.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllExportFolders(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllExportFolders, allExportFolders, levelsData]);
      useEffect(() => {
        if (filledAllPrefabFolders) {
            console.log(`OKAYYY`);
          const updatedLevelsData = levelsData.map((level) => {
            const levelFolderName = `Level`+`${level.levelID}`;
            console.log(`levelFolderName: ${levelFolderName}`);
            const matchingFolder = allPrefabFolders.find(folder => folder.foldername === levelFolderName);
      
            if (matchingFolder) {
              console.log(`Eşleşen klasör bulundu: ${matchingFolder.foldername}`);
              const dateInfo= new DateInfo(matchingFolder.creationDate);
              return {
                ...level,
                prefabFile: {
                  creationDate: matchingFolder.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFolder.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllPrefabFolders(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllPrefabFolders, allPrefabFolders, levelsData]);
      useEffect(() => {
        if (filledAllAndroidBundleFiles) {
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const levelFileName = `${level.levelID}`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allAndroidBundleFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= new DateInfo(matchingFile.creationDate);
              return {
                ...level,
                bundleAndroidFile: {
                  creationDate: matchingFile.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFile.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllAndroidBundleFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllAndroidBundleFiles, allAndroidBundleFiles, levelsData]);
      useEffect(() => {
        if (filledAllIOSBundleFiles) {
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const levelFileName = `${level.levelID}`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allIOSBundleFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= new DateInfo(matchingFile.creationDate);
              return {
                ...level,
                bundleIOSFile: {
                  creationDate: matchingFile.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFile.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllIOSBundleFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllIOSBundleFiles, allIOSBundleFiles, levelsData]);
      useEffect(() => {
        if (filledAllAndroidAwsFiles) {
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const config = gameConfigs[game]; 
            const levelFileName = `${config.bundleAndroidPrefix}${level.levelID}`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allAwsAndroidFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= matchingFile.dateInfo;
              return {
                ...level,
                awsAndroidFile: {
                  filename: matchingFile.filename,
                  dateInfo: dateInfo,
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllAndroidAwsFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllAndroidAwsFiles, allAwsAndroidFiles, levelsData]);
      useEffect(() => {
        if (filteredFileNames.length > 0 && !currentListName) {
            setCurrentListName(filteredFileNames[0]);
            downloadAndReadFile();
        }
    }, [filteredFileNames]);
      useEffect(() => {
        if (filledAllIOSAwsFiles) {
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const config = gameConfigs[game]; 
            const levelFileName = `${config.bundleIOSPrefix}${level.levelID}`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allAwsIOSFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= matchingFile.dateInfo;
              return {
                ...level,
                awsIOSFile: {
                  filename: matchingFile.filename,
                  dateInfo: dateInfo,
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledAllIOSAwsFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllIOSAwsFiles, allAwsIOSFiles, levelsData]);
      useEffect(() => {
        if (filledAllThumbnailFiles) {
          
          const updatedLevelsData = levelsData.map((level) => {
            // LevelID'ye .json ekleyerek filename ile karşılaştıracağız
            const levelFileName = `${level.levelID}_512x512_Thumbnail.png`;
      
            // allSourceFiles'daki eşleşen dosyayı buluyoruz
            const matchingFile = allThumbnailFiles.find(file => file.filename === levelFileName);
      
            if (matchingFile) {
              // Eşleşen dosya bulunduysa, level'ı güncelle
              console.log(`Eşleşen dosya bulundu: ${matchingFile.filename}`);
              const dateInfo= new DateInfo(matchingFile.creationDate);
              return {
                ...level,
                thumbnailFile: {
                  creationDate: matchingFile.creationDate,
                  dateInfo: dateInfo,
                  path: matchingFile.path
                }
              };
            } else {
              // Eşleşme yoksa level olduğu gibi kalır
              return level;
            }
          });
      
          // Güncellenmiş veriyi levelsData'ya set ediyoruz
          setLevelsData(updatedLevelsData);
          setError("");
          setfilledThumbnailFiles(false);
          console.log('LevelsData güncellendi:', updatedLevelsData);
        }
      }, [filledAllThumbnailFiles, allThumbnailFiles, levelsData]);

      const handleListSelection = (selectedList: string) => {
        console.log("Seçilen liste adı:", selectedList);
        setCurrentListName(selectedList);
        downloadAndReadFile(); // Seçilen listeyi indir ve işle
    };
    
    // Dosya indirme ve okuma işlemi
    const downloadAndReadFile = async () => {
        try {
            const config = gameConfigs[game];
            let levellistname = '';
    
            if (game === 'Find Differences') {
                // Find Differences için dosya adı oluştur
                const modeAddition = mode === "daily" ? "dailyList" : "regularList";
                levellistname = `${modeAddition}${levellistversion}.json`;
            } else if (game === 'Find Hidden Objects' || game === 'Find Master') {
                // Diğer iki oyun için kullanıcı dropdown'dan dosya seçer
                levellistname = currentListName+""; // Dropdown'dan doğrudan dosya adı
            }
    
            console.log("İndirilecek dosya adı:", levellistname); // Dosya adını kontrol edelim
            if (!levellistname || levellistname === 'null' || levellistname === '') {
                return;
            }
            const s3 = new AWS.S3();
            const params = {
                Bucket: config.bucket,
                Key: config.prefix + levellistname
            };
    
            const data = await s3.getObject(params).promise();
            console.log("İndirilen dosya içeriği:", data); // Dosyanın başarıyla indirildiğini kontrol edelim
    
            const jsonData = JSON.parse(data.Body?.toString() || '{}');
            console.log("JSON dosyasının içeriği:", jsonData); // JSON verisini kontrol edelim
    
            if (jsonData.levels) {
                console.log("Level Data:", jsonData.levels); // JSON'dan level data gelip gelmediğini kontrol edelim
                const updatedLevelsData = jsonData.levels.map((level: any) => ({
                    ...level,
                    sourceFile: {
                        creationDate: "İŞLEMDE",  // Varsayılan değer
                        path: ""  // Varsayılan boş path
                    }
                }));
    
                setLevelsData(updatedLevelsData);  // Levels data'yı varsayılan sourceFile ile güncelle
                setError("");
                setReadAws(true);
                setListCount(updatedLevelsData.length);
            } else {
                console.error("Dosya formatı beklenenden farklı, levels verisi bulunamadı.");
                setError("Format farkından dolayı dosya okunamadı");
                setLevelsData([]); // Eğer veri bulunamadıysa boş bir array olarak ayarla
            }
    
            // Dosya bilgilerini çekelim (Creation Date için)
            const headParams = {
                Bucket: config.bucket,
                Key: config.prefix + levellistname
            };
            const headData = await s3.headObject(headParams).promise();
            if (headData.LastModified) {
                const formattedDate = new Date(headData.LastModified).toLocaleString();
                console.log("Creation Date:", formattedDate); // Creation Date'in doğru çekildiğini kontrol edelim
                setCreationDate(formattedDate);
            } else {
                console.error("Creation Date alınamadı.");
            }
        } catch (err) {
            console.error("Dosya okunurken hata oluştu:", err);
            setError("Dosya okunurken bir hata oluştu");
            setLevelsData([]);  // Hata durumunda levelsData'yı boş bir dizi yapalım
        }
    };
    
    useEffect(() => {
        if(readAWS){
            const fetchS3Data = async () => {
                console.log("S3 dosya bundles kontrol ediliyor");
                const config = gameConfigs[game];
        
                AWS.config.region = config.region;
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: config.identityPoolId
                });
        
                try {
                    const credentials = AWS.config.credentials;
                    if (credentials instanceof AWS.Credentials) {
                        await new Promise((resolve, reject) => {
                            credentials.get((err?: AWS.AWSError) => {
                                if (err) reject(err);
                                else resolve(credentials);
                            });
                        });
                    } else {
                        setError("AWS credentials yapılandırılmadı.");
                        return;
                    }
        
                    const s3 = new AWS.S3();
                    const params = {
                        Bucket: config.bucket,
                        Prefix: config.bundleAndroidPrefix
                    };
        
                    const data = await s3.listObjectsV2(params).promise();
                    const fileInfos = data.Contents?.map(item => {
                        const lastModified = item.LastModified?.toISOString() || ''; // LastModified tarihini ISO formatında alıyoruz
                        return new AwsInfo(item.Key || 'Unknown', new DateInfo(lastModified));
                    }) || [];
        
                    // Dosya bilgilerini state'e set ediyoruz
                    setallAwsAndroidFiles(fileInfos);
                    setfilledAllAndroidAwsFiles(true);
                    console.log("AWS Android dosyaları alındı:", fileInfos);
        
                } catch (error) {
                    console.error("S3 dosyaları alınırken hata oluştu:", error);
                    setError("Dosyalar alınırken hata oluştu");
                }
            };
        
            fetchS3Data();
        }
       
    }, [readAWS]); 
    useEffect(() => {
        if(readAWS){
            const fetchS3Data = async () => {
                console.log("S3 dosya bundles kontrol ediliyor");
                const config = gameConfigs[game];
        
                AWS.config.region = config.region;
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: config.identityPoolId
                });
        
                try {
                    const credentials = AWS.config.credentials;
                    if (credentials instanceof AWS.Credentials) {
                        await new Promise((resolve, reject) => {
                            credentials.get((err?: AWS.AWSError) => {
                                if (err) reject(err);
                                else resolve(credentials);
                            });
                        });
                    } else {
                        setError("AWS credentials yapılandırılmadı.");
                        return;
                    }
        
                    const s3 = new AWS.S3();
                    const params = {
                        Bucket: config.bucket,
                        Prefix: config.bundleIOSPrefix
                    };
        
                    const data = await s3.listObjectsV2(params).promise();
                    const fileInfos = data.Contents?.map(item => {
                        const lastModified = item.LastModified?.toISOString() || ''; // LastModified tarihini ISO formatında alıyoruz
                        return new AwsInfo(item.Key || 'Unknown', new DateInfo(lastModified));
                    }) || [];
        
                    // Dosya bilgilerini state'e set ediyoruz
                    setallAwsIOSFiles(fileInfos);
                    setfilledAllIOSAwsFiles(true);
                    console.log("AWS Android dosyaları alındı:", fileInfos);
        
                } catch (error) {
                    console.error("S3 dosyaları alınırken hata oluştu:", error);
                    setError("Dosyalar alınırken hata oluştu");
                }
            };
        
            fetchS3Data();
        }
       
    }, [readAWS]); // game değiştiğinde tekrar çalışacak
    
    useEffect(() => {
        if (readAWS) {
            const fetchData = async () => {
                try {
                    const config = gameConfigs[game]; 
                    // Promise.all ile her iki fonksiyonu paralel olarak çalıştırıyoruz
                    await Promise.all([
                        fetchAllFilesFromFolder(config.folderIDs.filesFolder),
                        fetchAllFoldersFromFolder(config.folderIDs.foldersFolder),
                        fetchAllFoldersFromFolderPrefab(config.folderIDs.prefabFolder),
                        fetchAllFilesFromFolderAndroidBundle(config.folderIDs.androidBundleFolder),
                        fetchAllFilesFromFolderIOSBundle(config.folderIDs.iosBundleFolder),
                        fetchThumbnails(config.folderIDs.thumbnailFolder),
                    ]);
    
                    console.log("Veriler başarıyla alındı.");
                } catch (error) {
                    console.error("Veriler alınırken hata oluştu:", error);
                }
            };
    
            fetchData(); // Asenkron fonksiyonu çağırıyoruz
        }
    }, [readAWS]);  // readAWS değiştiğinde bu useEffect çalışacak
    
      
    interface Level2 {
        levelID: string;
        differenceCount?: number;  // Find Differences için optional
        itemCount?: number;  // Find Hidden Objects ve Find Master için optional
        difficulty?: number;  // Find Differences için difficulty seviyesi (0-4)
        hoCount?: number;  // Find Hidden Objects için item count (hoCount)
        sourceFile?: {
            creationDate: string;  // Dosyanın oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Dosyanın Drive üzerindeki path'i (link)
        };
        exportFile?: {
            creationDate: string;  // Export file oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Export file'ın Drive üzerindeki path'i
        };
        prefabFile?: {
            creationDate: string;  // Prefab file oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Prefab file'ın Drive üzerindeki path'i
        };
        bundleAndroidFile?: {
            creationDate: string;  // Bundle file oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Bundle file'ın Drive üzerindeki path'i
        };
        bundleIOSFile?: {
            creationDate: string;  // Bundle file oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Bundle file'ın Drive üzerindeki path'i
        };
        awsAndroidFile?: {
            filename: string;  // AWS file oluşturulma tarihi
            dateInfo: DateInfo;
        };
        awsIOSFile?: {
            filename: string;  // AWS file oluşturulma tarihi
            dateInfo: DateInfo;
        };
        thumbnailFile?: {
            creationDate: string;  // Thumbnail file oluşturulma tarihi
            dateInfo: DateInfo;
            path: string | null;  // Thumbnail file'ın Drive üzerindeki path'i
        };
    }
    
    
    const fetchAllFilesFromFolder = async (folderId: string): Promise<FileInfo[]> => {
        var allFiles: FileInfo[] = [];
    
        const fetchFilesRecursive = async (folderId: string) => {
            try {
                const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFiles', {
                    params: {
                        folderId,
                    },
                    withCredentials: true,
                });
    
                const files = response.data;
                console.log(`Folder ID ${folderId} içindeki dosyalar: `, files);
    
                for (const file of files) {
                    if (file.mimeType === 'application/vnd.google-apps.folder') {
                        // Alt klasör bulduk, onu da işleyelim
                        console.log(`Alt klasör bulundu: ${file.name}, ID: ${file.id}`);
                        await fetchFilesRecursive(file.id);
                    } else {
                        // Dosya bilgilerini alıp sınıf yapısına ekleyelim
                        
                        const fileInfo = new FileInfo(
                            file.name || 'Unknown',
                            file.creationDate || 'N/A',
                            new DateInfo( file.creationDate),
                            file.path || '#'
                        );
                        allFiles.push(fileInfo);
                        console.log(`Dosya eklendi: ${fileInfo.filename}`);
                    }
                }
            } catch (err) {
                console.error('Dosyalar alınırken hata oluştu:', err);
            }
        };
    
        await fetchFilesRecursive(folderId);
        setallSourceFiles(allFiles);
        setfilledAllSorceFiles(true);
        return allFiles;
    };

    const fetchRegularLevelListsFromAWS = async () => {
        console.log("AWS'den regularLevelList dosyaları çekiliyor...");
        const config = gameConfigs[game];
    
        AWS.config.region = config.region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId
        });
    
        try {
            const credentials = AWS.config.credentials;
            if (credentials instanceof AWS.Credentials) {
                await new Promise((resolve, reject) => {
                    credentials.get((err?: AWS.AWSError) => {
                        if (err) reject(err);
                        else resolve(credentials);
                    });
                });
            } else {
                setError("AWS credentials yapılandırılmadı.");
                return;
            }
    
            const s3 = new AWS.S3();
            const params = {
                Bucket: config.bucket,
                Prefix: config.prefix
            };
    
            const data = await s3.listObjectsV2(params).promise();
            const fileNames = data.Contents?.map(item => item.Key || '') || [];
    
            const regularLevelLists = fileNames
                .filter((fileName) => fileName.includes('regularLevelList')) // Filtreleme
                .map((fileName) => fileName.split('/').pop()) // Sadece dosya adı
                .filter((fileName): fileName is string => fileName !== undefined) // undefined değerleri temizle
                .sort();
    
            console.log("AWS'den çekilen regularLevelList dosyaları:", regularLevelLists);
            setFilteredFileNames(regularLevelLists);
    
        } catch (err) {
            setError("AWS dosyaları yüklenirken hata oluştu.");
            console.error("AWS hata:", err);
        }
    };
    
    
    class AwsInfo{
        constructor(
            public filename: string,
            public dateInfo: DateInfo
        ) {}
    }
   
    class FileInfo {
        constructor(
            public filename: string,
            public creationDate: string,
            public dateInfo: DateInfo,
            public path: string
        ) {}
    }
    class DateInfo {
        public creationDate: string;  // Orijinal formatta tarih
        public formattedDate: {
            year: number;
            month: number;
            day: number;
            hour: number;
            minute: number;
        };
    
        constructor(creationDate: string) {
            this.creationDate = creationDate;
    
            // Date'i ayırıp formatlı bir şekilde saklama
            const dateObj = new Date(creationDate);
            this.formattedDate = {
                year: dateObj.getUTCFullYear(),
                month: dateObj.getUTCMonth() + 1,  // Ay 0 tabanlı olduğu için 1 ekliyoruz
                day: dateObj.getUTCDate(),
                hour: dateObj.getUTCHours(),
                minute: dateObj.getUTCMinutes()
            };
        }
    }
    function formatLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.sourceFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatExportLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.exportFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatPrefabLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.prefabFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatAndroidBundleLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.bundleAndroidFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatIOSBundleLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.bundleIOSFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatAwsAndroidLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.awsAndroidFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatAwsIOSLevelDate(level: any): string {
        const { year, month, day, hour, minute } = level.awsIOSFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    function formatThumbnaillDate(level: any): string {
        const { year, month, day, hour, minute } = level.thumbnailFile.dateInfo.formattedDate;
    
        // Ay ve gün bilgilerini iki haneli yapmak için padStart kullanıyoruz
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
    
        // İstenen formatta sonucu döndür
        return `${year}-${formattedMonth}-${formattedDay} (${formattedHour}:${formattedMinute})`;
    }
    const fetchAllFoldersFromFolder = async (folderId: string): Promise<FolderInfo[]> => {
        let allFolders: FolderInfo[] = [];
    
        try {
            console.log("Fetching folders...");
            const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFolders', {
                params: { folderId },
                withCredentials: true,
            });
    
            const folders = response.data;
            console.log(`Folder ID ${folderId} içindeki klasörler: `, folders);
    
            // Gelen klasörleri FolderInfo sınıfına ekleyelim
            const folderInfos: FolderInfo[] = folders.map((folder: any): FolderInfo => {
                return new FolderInfo(
                    folder.name || 'Unknown',
                    folder.createdTime || 'N/A',
                    new DateInfo(folder.createdTime),
                    folder.webViewLink || '#'
                );
            });
    
            allFolders = allFolders.concat(folderInfos);
    
        } catch (err) {
            console.error('Klasörler alınırken hata oluştu:', err);
        }
    
        // Sonuçları state'e set edelim
        setallExportFolders(allFolders);
        setfilledAllExportFolders(true);
    
        return allFolders;
    };
    const fetchAllFoldersFromFolderPrefab = async (folderId: string): Promise<FolderInfo[]> => {
        let allFolders: FolderInfo[] = [];
    
        try {
            console.log("Fetching folders...");
            const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFolders', {
                params: { folderId },
                withCredentials: true,
            });
    
            const folders = response.data;
            console.log(`Folder ID ${folderId} içindeki klasörler: `, folders);
    
            // Gelen klasörleri FolderInfo sınıfına ekleyelim
            const folderInfos: FolderInfo[] = folders.map((folder: any): FolderInfo => {
                return new FolderInfo(
                    folder.name || 'Unknown',
                    folder.createdTime || 'N/A',
                    new DateInfo(folder.createdTime),
                    folder.webViewLink || '#'
                );
            });
    
            allFolders = allFolders.concat(folderInfos);
    
        } catch (err) {
            console.error('Klasörler alınırken hata oluştu:', err);
        }
    
        // Sonuçları state'e set edelim
        setallPrefabFolders(allFolders);
        setfilledAllPrefabFolders(true);
    
        return allFolders;
    };
    const fetchAllFilesFromFolderAndroidBundle = async (folderId: string): Promise<FileInfo[]> => {
        var allFiles: FileInfo[] = [];
    
        const fetchFilesRecursive = async (folderId: string) => {
            try {
                const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFiles', {
                    params: {
                        folderId,
                    },
                    withCredentials: true,
                });
    
                const files = response.data;
                console.log(`Folder ID ${folderId} içindeki dosyalar: `, files);
    
                for (const file of files) {
                    if (file.mimeType === 'application/vnd.google-apps.folder') {
                        // Alt klasör bulduk, onu da işleyelim
                        console.log(`Alt klasör bulundu: ${file.name}, ID: ${file.id}`);
                        await fetchFilesRecursive(file.id);
                    } else {
                        // Dosya bilgilerini alıp sınıf yapısına ekleyelim
                        
                        const fileInfo = new FileInfo(
                            file.name || 'Unknown',
                            file.creationDate || 'N/A',
                            new DateInfo( file.creationDate),
                            file.path || '#'
                        );
                        allFiles.push(fileInfo);
                        console.log(`Dosya eklendi: ${fileInfo.filename}`);
                    }
                }
            } catch (err) {
                console.error('Dosyalar alınırken hata oluştu:', err);
            }
        };
    
        await fetchFilesRecursive(folderId);
        setallAndroidBundleFiles(allFiles);
        setfilledAllAndroidBundleFiles(true);
        return allFiles;
    };
    const fetchAllFilesFromFolderIOSBundle = async (folderId: string): Promise<FileInfo[]> => {
        var allFiles: FileInfo[] = [];
    
        const fetchFilesRecursive = async (folderId: string) => {
            try {
                const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFiles', {
                    params: {
                        folderId,
                    },
                    withCredentials: true,
                });
    
                const files = response.data;
                console.log(`Folder ID ${folderId} içindeki dosyalar: `, files);
    
                for (const file of files) {
                    if (file.mimeType === 'application/vnd.google-apps.folder') {
                        // Alt klasör bulduk, onu da işleyelim
                        console.log(`Alt klasör bulundu: ${file.name}, ID: ${file.id}`);
                        await fetchFilesRecursive(file.id);
                    } else {
                        // Dosya bilgilerini alıp sınıf yapısına ekleyelim
                        
                        const fileInfo = new FileInfo(
                            file.name || 'Unknown',
                            file.creationDate || 'N/A',
                            new DateInfo( file.creationDate),
                            file.path || '#'
                        );
                        allFiles.push(fileInfo);
                        console.log(`Dosya eklendi: ${fileInfo.filename}`);
                    }
                }
            } catch (err) {
                console.error('Dosyalar alınırken hata oluştu:', err);
            }
        };
    
        await fetchFilesRecursive(folderId);
        setallIOSBundleFiles(allFiles);
        setfilledAllIOSBundleFiles(true);
        return allFiles;
    };
    const fetchThumbnails = async (folderId: string): Promise<FileInfo[]> => {
        var allFiles: FileInfo[] = [];
    
        const fetchFilesRecursive = async (folderId: string) => {
            try {
                const response = await axios.get('https://levels-462nclm3ma-uc.a.run.app/listFiles', {
                    params: {
                        folderId,
                    },
                    withCredentials: true,
                });
    
                const files = response.data;
                console.log(`Folder ID ${folderId} içindeki dosyalar: `, files);
    
                for (const file of files) {
                    if (file.mimeType === 'application/vnd.google-apps.folder') {
                        // Alt klasör bulduk, onu da işleyelim
                        console.log(`Alt klasör bulundu: ${file.name}, ID: ${file.id}`);
                        await fetchFilesRecursive(file.id);
                    } else {
                        // Dosya bilgilerini alıp sınıf yapısına ekleyelim
                        
                        const fileInfo = new FileInfo(
                            file.name || 'Unknown',
                            file.creationDate || 'N/A',
                            new DateInfo( file.creationDate),
                            file.path || '#'
                        );
                        allFiles.push(fileInfo);
                        console.log(`Dosya eklendi: ${fileInfo.filename}`);
                    }
                }
            } catch (err) {
                console.error('Dosyalar alınırken hata oluştu:', err);
            }
        };
    
        await fetchFilesRecursive(folderId);
        setallThumbnailFiles(allFiles);
        setfilledThumbnailFiles(true);
        return allFiles;
    };
    
    class FolderInfo {
        constructor(
            public foldername: string,
            public creationDate: string,
            public dateInfo: DateInfo,
            public path: string
        ) {}
    }
      
    
    const handleDownloadCSV = () => {
        const csvRows = [];

        // Başlıkları tanımla
        const headers = [
            'Level Order',
            'Level ID',
            game === 'Find Hidden Objects' ? 'Hidden Object Count' : 'Difference Count',
        ];

        if (game === 'Find Differences') {
            headers.push('Difficulty / Complexity');
        } else {
            headers.push('Source File');
        }

        headers.push(
            'Export',
            'Prefab',
            'Bundle Android',
            'Bundle IOS',
            'AWS S3(Android)',
            'AWS S3(IOS)',
            'Thumbnail'
        );

        csvRows.push(headers.join(','));

        // Her seviye için satır oluştur
        sortedLevelsData.forEach((level: any, index: number) => {
            const levelOrder = sortOrder === 'ascending' ? index : levelsData.length - 1 - index;

            const row = [];

            // Level Order
            row.push(levelOrder);

            // Level ID
            row.push(level.levelID);

            // Difference Count veya Item Count
            const count = game === 'Find Hidden Objects'
                ? level.hoCount || 'N/A'
                : level.differenceCount || level.itemCount || 'N/A';
            row.push(count);

            // Difficulty / Complexity veya Source File
            if (game === 'Find Differences') {
                let difficultyText = 'N/A';
                switch (level.difficulty) {
                    case 0:
                        difficultyText = 'EASY';
                        break;
                    case 1:
                        difficultyText = 'MEDIUM';
                        break;
                    case 2:
                        difficultyText = 'HARD';
                        break;
                    case 3:
                        difficultyText = 'NONE';
                        break;
                    case 4:
                        difficultyText = 'VERY EASY';
                        break;
                    default:
                        break;
                }
                row.push(difficultyText);
            } else {
                let sourceFileText = 'N/A';
                if (processingLevels.includes(level.levelID)) {
                    sourceFileText = 'İŞLEMDE';
                } else if (level.sourceFile && level.sourceFile.path) {
                    sourceFileText = `${formatLevelDate(level)} (${level.sourceFile.path})`;
                }
                row.push(sourceFileText);
            }

            // Export File
            let exportFileText = 'N/A';
            if (level.exportFile && level.exportFile.path) {
                exportFileText = `${formatExportLevelDate(level)} (${level.exportFile.path})`;
            }
            row.push(exportFileText);

            // Prefab File
            let prefabFileText = 'N/A';
            if (level.prefabFile && level.prefabFile.path) {
                prefabFileText = `${formatPrefabLevelDate(level)} (${level.prefabFile.path})`;
            }
            row.push(prefabFileText);

            // Bundle Android File
            let bundleAndroidFileText = 'N/A';
            if (level.bundleAndroidFile && level.bundleAndroidFile.path) {
                bundleAndroidFileText = `${formatAndroidBundleLevelDate(level)} (${level.bundleAndroidFile.path})`;
            }
            row.push(bundleAndroidFileText);

            // Bundle IOS File
            let bundleIOSFileText = 'N/A';
            if (level.bundleIOSFile && level.bundleIOSFile.path) {
                bundleIOSFileText = `${formatIOSBundleLevelDate(level)} (${level.bundleIOSFile.path})`;
            }
            row.push(bundleIOSFileText);

            // AWS Android File
            let awsAndroidFileText = 'N/A';
            if (level.awsAndroidFile && level.awsAndroidFile.dateInfo && level.awsAndroidFile.dateInfo.creationDate) {
                awsAndroidFileText = formatAwsAndroidLevelDate(level);
            }
            row.push(awsAndroidFileText);

            // AWS IOS File
            let awsIOSFileText = 'N/A';
            if (level.awsIOSFile && level.awsIOSFile.dateInfo && level.awsIOSFile.dateInfo.creationDate) {
                awsIOSFileText = formatAwsIOSLevelDate(level);
            }
            row.push(awsIOSFileText);

            // Thumbnail
            let thumbnailText = 'N/A';
            if (level.thumbnailFile && level.thumbnailFile.path) {
                thumbnailText = `${formatThumbnaillDate(level)} (${level.thumbnailFile.path})`;
            }
            row.push(thumbnailText);

            // Verilerdeki tırnak işaretlerini kaçış karakteriyle değiştir
            const escapedRow = row.map((field) => `"${String(field).replace(/"/g, '""')}"`);

            // Satırı birleştir ve csvRows'a ekle
            csvRows.push(escapedRow.join(','));
        });

        // CSV stringini oluştur
        const csvString = csvRows.join('\n');

        // CSV stringinden Blob oluştur
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        // Blob'u indirmek için link oluştur
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // HTML5 indirme özelliğini destekleyen tarayıcılar
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'level_data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            paddingTop: '20px',
            height: '80vh',
            backgroundColor: '#e36200'
        }}>
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', maxWidth: '90%', width: '90%', textAlign: 'center' }}>
                <h1>Levellist Management</h1>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: '15px' }}>
                    {/* Game Dropdown */}
                    <label htmlFor="menu" style={{ textAlign: 'left', fontWeight: 'bold', color: '#555' }}>Game</label>
                    <select id="menu" value={game} onChange={(e) => setGame(e.target.value as keyof typeof gameConfigs)} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}>
                        <option value="Find Differences">Differences</option>
                        <option value="Find Hidden Objects">Hidden</option>
                        <option value="Find Master">Master</option>
                    </select>

                    {/* Mode Dropdown - Sadece Find Differences için görünür */}
                    {game === 'Find Differences' && (
                        <>
                            <label htmlFor="modeMenu" style={{ textAlign: 'left', fontWeight: 'bold', color: '#555' }}>Mode</label>
                            <select id="modeMenu" value={mode} onChange={(e) => setMode(e.target.value)} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}>
                                <option value="regular">Regular</option>
                                <option value="daily">Daily</option>
                            </select>
                        </>
                    )}
                    
                    {/* Levellist Dropdown for Find Differences */}
{game === 'Find Differences' && (
     <>
    <label htmlFor="levellistMenu" style={{ textAlign: 'left', fontWeight: 'bold', color: '#555' }}>Levellist Version</label>
    <select
        id="levellistMenu"
        value={levellistversion}
        onChange={(e) => setLevellistversion(e.target.value)}
        style={{ width: '100%', padding: '10px', borderRadius: '5px', fontSize: '16px' }}
    >
        {filteredNumbers.length === 0 ? (
            <option>--Veri yok--</option>
        ) : (
            filteredNumbers.map((num, index) => (
                <option key={index} value={num.toString()}>{num}</option>
            ))
        )}
    </select>
    </>)}

               {/* Levellist Dropdown for Find Hidden Objects & Find Master */}
               {(game === 'Find Hidden Objects' || game === 'Find Master') && (
    <>
        <label htmlFor="levellistMenu" style={{ textAlign: 'left', fontWeight: 'bold', color: '#555' }}>Levellist Version</label>
        <select
            id="levellistMenu"
            value={currentListName || ""}
            onChange={(e) => {setLevellistversion(e.target.value); handleListSelection(e.target.value);}}
            style={{ width: '100%', padding: '10px', borderRadius: '5px', fontSize: '16px' }}
        >
            {filteredFileNames.length === 0 ? (
                <option>--Veri yok--</option>
            ) : (
                sortLevellistVersions(filteredFileNames).map((fileName, index) => (
                    <option key={index} value={fileName}>{fileName}</option>
                ))
            )}
        </select>
    </>
)}


                    {/* Hata Mesajı */}
                    {error && <p style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>{error}</p>}
                </div>

                <button
                    onClick={async () => {
                        console.log("Show button clicked");
                        setShowCard(true);
                        setIsLoading(true);  // Loading durumunu başlat
                        setReadAws(false);
                        setProcessingLevels([]);
                        setallSourceFiles([]);
                        setfilledAllSorceFiles(false);
                        setallExportFolders([]);
                        setfilledAllExportFolders(false);
                        setallPrefabFolders([]);
                        setfilledAllPrefabFolders(false);
                        setallAndroidBundleFiles([]);
                        setfilledAllAndroidBundleFiles(false);
                        setallIOSBundleFiles([])
                        setfilledAllIOSBundleFiles(false);
                        setallAwsAndroidFiles([]);
                        setfilledAllAndroidAwsFiles(false);
                        setallAwsIOSFiles([]);
                        setfilledAllIOSAwsFiles(false);
                        setallThumbnailFiles([]);
                        setfilledThumbnailFiles(false);
                        setSortOrder('descending');
                        await downloadAndReadFile(); // Dosya indirildikten sonra diğer işlemleri başlat
                        setIsLoading(false);  // Loading durumu tamamlandı
                    }}
                    style={{ marginTop: '20px', padding: '10px', borderRadius: '5px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
                >
                    Show
                </button>
            </div>

            {/* Loading durumu */}
            {isLoading && <p>Loading...</p>}

            {/* Yeni Card */}
            {showCard && !isLoading && (
                <div style={{
                    marginTop: '20px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    maxWidth: '90%',
                    width: '90%',
                    textAlign: 'center'
                }}>
                    {game === 'Find Differences' && <h2>List Properties</h2>}
                    

                    {/* Level Count */}
                    {game === 'Find Differences' && <p>Level Count: {levelsData ? levelsData.length : "No data"}</p>}
                    
                    {/* Creation Date */}
                    {game === 'Find Differences' && creationDate && <p>Creation Date: {creationDate}</p>}

                    {/* Activation Date */}
                    {activationDate && game === 'Find Differences' && remoteConfigValue === currentListVersion && (
                        <p>Activation Date: {activationDate}</p>
                    )}
                    {/* Current List Version - Sadece Find Differences için */}
                    {game === 'Find Differences' && currentListVersion && (
                        <p>List Version In Game: {currentListVersion}</p>
                    )}

{/* List Properties */}
<div style={{
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center', // Yatay hizalama
    alignItems: 'center', // Dikey hizalama
    width: '100%',
}}>
    <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
        maxWidth: '600px', // Maksimum genişlik belirlendi
        width: '100%',
        textAlign: 'center'
    }}>
        <h2>List Properties</h2>

        {game === 'Find Differences' ? (
            <>
                <p>Level Count: {levelsData.length}</p>
                {creationDate && <p>Creation Date: {creationDate}</p>}
                {activationDate && <p>Activation Date: {activationDate}</p>}
            </>
        ) : (
            <>
                <p>Levellist Creation Date: {creationDate || 'N/A'}</p>
                <p>Max Level Count: {Math.max(...activationLevelTimeline.map(entry => entry.levelCount), 0)}</p>
                <p>Activation Level Count:</p>

                <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Activation Date</th>
                            <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Level Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activationLevelTimeline.map((entry, index) => {
                            const isCurrent = entry.activationDate === currentActivationDate;
                            return (
                                <tr key={index} style={{
                                    backgroundColor: isCurrent ? '#d4edda' : 'transparent',
                                    color: isCurrent ? '#155724' : 'black'
                                }}>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        {new Date(entry.activationDate).toLocaleString()}
                                    </td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        {entry.levelCount}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        )}
    </div>
</div>

                    
                </div>
            )}

            {/* Excel tarzı tablo */}
            {showCard && !isLoading && levelsData && (
                <div style={{
                    marginTop: '20px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                    maxWidth: '90%',
                    width: '90%',
                    textAlign: 'center'
                }}>

                    {/* Başlık ve Buton */}
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <h2 style={{ marginRight: '20px' }}>Level Data Table</h2>
    <button
        style={{ height: '40px', width: '120px' }}
        onClick={handleDownloadCSV}
    >
        Download CSV
    </button>
</div>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        ...tableHeaderStyle,
                                        backgroundColor: sortOrder === 'ascending' ? 'green' : tableHeaderStyle.backgroundColor,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending')}
                                >
                                    Level Order
                                </th>
                                <th style={tableHeaderStyle}>Level ID</th>
                                <th style={tableHeaderStyle}>
                                    {game === 'Find Hidden Objects' ? 'Hidden Object Count' : 'Difference Count'}
                                </th>
                                {game === 'Find Differences' && <th style={tableHeaderStyle}>Difficulty / Complexity</th>}
                                {game !== 'Find Differences' && <th style={tableHeaderStyle}>Source File</th>}
                                <th style={tableHeaderStyle}>Export</th>
                                <th style={tableHeaderStyle}>Prefab</th>
                                <th style={tableHeaderStyle}>Bundle Android</th>
                                <th style={tableHeaderStyle}>Bundle IOS</th>
                                <th style={tableHeaderStyle}>AWS S3(Android)</th>
                                <th style={tableHeaderStyle}>AWS S3(IOS)</th>
                                <th style={tableHeaderStyle}>Thumbnail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedLevelsData.map((level: any, index: number) => {
                                const levelOrder = sortOrder === 'ascending' ? index : levelsData.length - 1 - index;
                                return (
                                    <tr key={index}>
                                        <td style={tableCellStyle}>{levelOrder}</td>
                                        <td style={tableCellStyle}>{level.levelID}</td>

                                        {/* Difference Count or Item Count */}
                                        <td style={tableCellStyle}>
                                            {game === 'Find Hidden Objects'
                                                ? level.hoCount || 'N/A'
                                                : level.differenceCount || level.itemCount || 'N/A'}
                                        </td>

                                        {/* Difficulty / Complexity */}
                                        {game === 'Find Differences' && (
                                            <td
                                                style={{
                                                    ...tableCellStyle,
                                                    backgroundColor:
                                                        level.difficulty === 0 ? 'green' :
                                                        level.difficulty === 1 ? 'yellow' :
                                                        level.difficulty === 2 ? 'red' :
                                                        level.difficulty === 3 ? 'grey' :
                                                        level.difficulty === 4 ? 'darkgreen' : 'transparent',
                                                    color: level.difficulty === 0 || level.difficulty === 4 ? 'white' : 'black',
                                                }}
                                            >
                                                {level.difficulty === 0
                                                    ? 'EASY'
                                                    : level.difficulty === 1
                                                        ? 'MEDIUM'
                                                        : level.difficulty === 2
                                                            ? 'HARD'
                                                            : level.difficulty === 3
                                                                ? 'NONE'
                                                                : level.difficulty === 4
                                                                    ? 'VERY EASY'
                                                                    : 'N/A'}
                                            </td>
                                        )}

                                        {/* Source File */}
                                        {game !== 'Find Differences' && (
                                            <td style={tableCellStyle}>
                                                {processingLevels.includes(level.levelID)
                                                    ? "İŞLEMDE"
                                                    : (level.sourceFile && level.sourceFile.path
                                                        ? <a href={level.sourceFile.path} target="_blank" rel="noopener noreferrer">
                                                            {formatLevelDate(level)}
                                                        </a>
                                                        : 'N/A')
                                                }
                                            </td>
                                        )}
                                        {/* Export File */}
                                        <td style={tableCellStyle}>
                                            {level.exportFile && level.exportFile.path
                                                ? <a href={level.exportFile.path} target="_blank" rel="noopener noreferrer">
                                                    {formatExportLevelDate(level)}
                                                </a>
                                                : 'N/A'}
                                        </td>
                                        {/* Prefab File */}
                                        <td style={tableCellStyle}>
                                            {level.prefabFile && level.prefabFile.path
                                                ? <a href={level.prefabFile.path} target="_blank" rel="noopener noreferrer">
                                                    {formatPrefabLevelDate(level)}
                                                </a>
                                                : 'N/A'}
                                        </td>
                                        {/* Android Bundle File */}
                                        <td style={tableCellStyle}>
                                            {level.bundleAndroidFile && level.bundleAndroidFile.path
                                                ? <a href={level.bundleAndroidFile.path} target="_blank" rel="noopener noreferrer">
                                                    {formatAndroidBundleLevelDate(level)}
                                                </a>
                                                : 'N/A'}
                                        </td>
                                        {/* IOS Bundle File */}
                                        <td style={tableCellStyle}>
                                            {level.bundleIOSFile && level.bundleIOSFile.path
                                                ? <a href={level.bundleIOSFile.path} target="_blank" rel="noopener noreferrer">
                                                    {formatIOSBundleLevelDate(level)}
                                                </a>
                                                : 'N/A'}
                                        </td>
                                        {/* AWS Android File */}
                                        <td style={tableCellStyle}>
                                            {level.awsAndroidFile && level.awsAndroidFile.dateInfo.creationDate
                                                ? formatAwsAndroidLevelDate(level)
                                                : "N/A"}
                                        </td>
                                        {/* AWS IOS File */}
                                        <td style={tableCellStyle}>
                                            {level.awsIOSFile && level.awsIOSFile.dateInfo.creationDate
                                                ? formatAwsIOSLevelDate(level)
                                                : "N/A"}
                                        </td>
                                        <td style={tableCellStyle}>
                                            {level.thumbnailFile && level.thumbnailFile.path
                                                ? <a
                                                    href={level.thumbnailFile.path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {formatThumbnaillDate(level)}
                                                </a>
                                                : 'N/A'}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    );
};
    
export default Levels;