// src/components/Navbar.tsx

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../services/firebase';
import { onAuthStateChanged, User } from 'firebase/auth';

const Navbar: React.FC = () => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });

        // Bileşen unmount olduğunda aboneliği temizleyin
        return () => unsubscribe();
    }, []);

    return (
        <nav className="navbar">
            <h2 className="navbar-title">Yolo Game Studios</h2>
            <ul className="nav-links">
                <li>
                    <Link to="/" className="nav-link">
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/analytics" className="nav-link">
                        Analytics
                    </Link>
                </li>
                <li>
                    <Link to="/levels" className="nav-link">
                        Levels
                    </Link>
                </li>
                <li>
                    <Link to="/zendesk" className="nav-link">
                        Zendesk
                    </Link>
                </li>
                <li>
                    <Link to="/logs" className="nav-link">
                        Logs
                    </Link>
                </li>

            </ul>
        </nav>
    );
};

export default Navbar;