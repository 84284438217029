// src/App.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import Levels from './pages/Levels'; // 'Levels' sayfasını içe aktarıyoruz
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import Zendesk from './pages/Zendesk'; // 'Zendesk' sayfasını içe aktarıyoruz
import Logs from './pages/Logs'; // 'Logs' sayfasını içe aktarıyoruz
import Status from './pages/Status'; // 'Status' sayfasını içe aktarıyoruz

function App() {
    return (
        <Router>
            <Routes>
                {/* JobStatus'un olduğu sayfalarda Navbar'ı göstermiyoruz */}
                <Route
                    path="/status/:id"
                    element={
                        < Status/>
                    }
                />

                {/* Navbar'lı sayfalar */}
                <Route
                    path="*"
                    element={
                        <>
                            <Navbar />
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <PrivateRoute>
                                            <Home />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/analytics"
                                    element={
                                        <PrivateRoute>
                                            <Analytics />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/levels"
                                    element={
                                        <PrivateRoute>
                                            <Levels />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/zendesk"
                                    element={
                                        <PrivateRoute>
                                            <Zendesk />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/logs"
                                    element={
                                        <PrivateRoute>
                                            <Logs />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/login" element={<Login />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;